import React from "react";

import Navigation from "../components/navigation";

export default () => (
    <Navigation>
        <div>
            <title>David Weik - Contact</title>
            <div>
                <h1 id="main-title" style={{ textAlign: "center" }}>
                    Contact
                </h1>
                <p style={{ margin: "0rem 1rem 1rem 1rem" }}>
                    Want to get in touch? The best way is to send me an E-Mail{" "}
                    <a href="mailto:weik.david@protonmail.com?subject=Contact">
                        weik.david@protonmail.com
                    </a>
                    . If you prefer social media you can also send me a message
                    on Twitter @
                    <a href="https://twitter.com/weik_david">weik_david</a>.
                </p>
            </div>
        </div>
    </Navigation>
);
